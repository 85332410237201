import { useCallback } from 'react';
import useApiCall from 'store/useApiCall';
import { API_AUTH } from 'utils/apiUtils/apis';
import { setLocalStorage } from 'utils/commonFunc';
import { CONST_LOADING_SIGNIN, CONST_LOGGED_USER, CONST_LOCAL_STORAGE_LOGGED_USER, CONST_POST } from 'utils/constants';

const useAuthMethodHandler = ({ contextState }) => {
  const api = useApiCall();

  const userLoginService = useCallback(
    async loginData => {
      const res = await api({
        method: CONST_POST,
        payload: { userName: loginData?.email, password: loginData?.password },
        url: API_AUTH.signin.api,
        loadingParam: CONST_LOADING_SIGNIN,
        stateParam: CONST_LOGGED_USER,
        setState: contextState?.setAuthState,
        contextState,
        sourceFormat: [
          { actual: 'accessToken', change: 'userToken' },
          { actual: 'fullName', change: 'userName' },
          { actual: 'email', change: 'userEmail' },
          { actual: 'userRole', change: 'userRole' },
          { actual: 'referenceId', change: 'userId' },
          { actual: 'branchId', change: 'orgId' },
        ],
        returnType: 'object',
      });
      setLocalStorage(CONST_LOCAL_STORAGE_LOGGED_USER, res?.data);
      return res;
    },
    [api, contextState]
  );

  return { userLoginService };
};

export default useAuthMethodHandler;
